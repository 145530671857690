// i18next-extract-mark-ns-start products-fearce-model

import { FEARCE_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const ProductFearcePage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation(); return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="narrow"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content">
						<p>
							FEARCE has an integrated graphical user interface (GUI)
							that enables users to construct and assemble complex
							models by using components with identified key
							connectivity points. All types of connectivity can be
							applied between component contact surfaces, defined by
							surface geometry. The integrated GUI enables components
							to be easily imported, displayed, documented and altered
							within various key stages of the build process.
						</p>
						<p>
							FEARCE also provides tools for the automatic generation
							of bolts. The user can define the head and thread
							contact regions along with the bolt-shaft diameter and
							material type. FEARCE will then generate the correct
							beam and connection. Alternatively, FEARCE can generate
							solid bolts based on specific dimensions from the user.
						</p>
						<h2>Key features</h2>
						<ul>
							<li>
								Importing component models from different sources
							</li>
							<li>
								Scaling, translating and transforming models for
								assembly
							</li>
							<li>
								Enables copying of repeated components to reduce
								modelling overhead
							</li>
							<li>
								Joins are based on mating surfaces rather than node
							</li>
							<li>
								Various types of connections can be generated
								automatically
							</li>
							<li>
								Automatic joining of dissimilar meshes using
								multi-point constraints
							</li>
							<li>
								Automatic checking of assemblies – with conflicts,
								fixes and warnings flagged
							</li>
							<li>Automatic generation of bolts</li>
						</ul>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_Applications")}
						className="widget__sibling__pages">
						<AssetList>
							{FEARCE_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
				</Aside>
			</PageSection>
		</Page>
	);
};

export default ProductFearcePage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["products-fearce-model", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/fearce-hero.jpg" }) {
		...imageBreaker
	}
}
`;
